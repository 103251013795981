import React, { useContext, useState } from "react"
import { AppContext } from "../context/context"
import PropTypes from "prop-types"
import BgImg from "gatsby-background-image"
import Img from "gatsby-image"
import * as styles from "./serviceHero.module.scss"
import ServiceTile from "./ServiceTile"

//TODO we need to style the images based on width and this bgImg container
//And we should use useState to keep track of the active ServiceTile maybe here
//or in ServiceTile.js And then we need to style all of the things in ServiceTile.js

const ServiceHero = ({ banner, phoneNumber }) => {
  const { size } = useContext(AppContext)
  const { desktop, mobile, secondary_image, services } = banner
  const [selectedServiceIndex, setSelectedService] = useState(0)

  const sources = [
    mobile.value[0].fluid,
    {
      ...desktop.value[0].fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <BgImg
      fluid={sources}
      alt={desktop.value[0].description}
      className={styles.hero}
    >
      <div className={`BlackBg ${styles.opacity}`}></div>
      {services.value.map((service, i) => {
        return (
          <ServiceTile
            services={services}
            service={service}
            key={service.id}
            phoneNumber={phoneNumber}
            i={i}
            selectedServiceIndex={selectedServiceIndex}
            setSelectedService={setSelectedService}
          />
        )
      })}
      {secondary_image.value.length && size >= 1200 && (
        <Img
          fluid={secondary_image.value[0].fluid}
          alt={secondary_image.value[0].description}
          className={styles.additionalImage}
        />
      )}
    </BgImg>
  )
}

ServiceHero.propTypes = {
  banner: PropTypes.object.isRequired,
  price: PropTypes.string,
}

ServiceHero.defaultProps = {
  banner: {},
  price: "29.00",
}

export default ServiceHero
